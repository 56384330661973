import * as React from 'react';
import {PageBackground} from '../../components/PageBackground';
import {PageHeader} from '../../components/PageHeader';
import {VSpacer} from '../../components/VSpacer';
import {Images} from '../../images/Images';
import {HAlign, HAligner} from '../../components/HAligner';
import {Text, TextSize, TextWeight} from '../../components/Text';
import {PageFooter} from '../../components/PageFooter';
import {PictureFrame} from '../../components/PictureFrame';
import {CareersLink} from '../../components/CareersLink';

export class CareersPage extends React.Component<any, any>
{
    render()
    {
        return  <PageBackground>
                    <PageHeader/>
                    <VSpacer size={15}/>

                    <div className={'container'} style={{color:'black', border:'1px solid transparent'}}>
                        <div className={'row'} style={{marginBottom:'0px'}}>
                            <div className={'col-sm-12'}>

                                <Text isHighlight={false} size={TextSize.h2} weight={TextWeight.w500}>
                                    About Us
                                </Text>
                                <VSpacer size={20}/>
                                <Text isHighlight={false} size={TextSize.h3} weight={TextWeight.w300}>

                                    A team of software developers and quality assurance experts, we're constantly on the lookout for solving the next big challenge.

                                    We work on a number of software systems across several verticals, and we are always looking for bright minds to join our team.

                                    Everyone on our team is constantly learning new things, and we look forward to working together with you.
                                    <br/><br/>

                                    {/*As a team of developers, designers, and product development leaders, we’re a tightly knit team passionate about what */}
                                    {/*the future of technology will bring. Each of us has deep domain expertise in our field, and we have a huge sense of camaraderie in sharing our technical knowledge within the team.*/}
                                    {/*<br/><br/>*/}
                                    {/*Everyone on our team is constantly learning new technologies, algorithms, distributed systems approaches, and new ways to solve problems.*/}

                                </Text>
                            </div>
                        </div>
                    </div>

                    <div className={'container'} style={{color:'black', border:'1px solid transparent'}}>
                        <div className={'row'} style={{marginBottom:'20px'}}>
                            <div className={'col-sm-12'}>

                                <Text isHighlight={false} size={TextSize.h2} weight={TextWeight.w500}>
                                    Join Us
                                </Text>
                                <VSpacer size={20}/>
                                <Text isHighlight={false} size={TextSize.h3} weight={TextWeight.w300}>
                                    We're always looking for brilliant engineers with a sense of passion and drive to change the world around us.
                                    Below are our open positions. We encourage you to get in touch at <CareersLink/>.
                                    {/*The list below is our set of open positions. */}
                                </Text>
                                <VSpacer size={20}/>
                            </div>
                        </div>
                    </div>
                    <div className={'container'} style={{color:'black', border:'1px solid transparent'}}>
                        <div className={'row'} style={{marginBottom:'20px', marginLeft:'50px'}}>
                            <div className={'col-md-5'} style={{}}>
                                <Text isHighlight={false} size={TextSize.h3} weight={TextWeight.w500}>
                                    {/*<a href={'/careers/position42'}>→ Product Owner</a><br/>*/}
                                    {/*<a href={'/careers/position43'}>→ Senior .NET Software Engineer</a><br/>*/}
                                    <a href={'/careers/position45'}>→ Senior Quality Assurance Engineer</a><br/>
                                    {/*<a href={'/careers/position44'}>→ Senior Fullstack Software Engineer (.Net)</a><br/>*/}
                                    {/*<a href={'/careers/position46'}>→ Senior Platform Front-end Engineer (React)</a><br/>*/}
                                    <a href={'/careers/position47'}>→ Senior Platform Backend Engineer (Java)</a><br/>
                                </Text>
                            </div>
                            {/*<div className={'col-md-7'} style={{}}>*/}
                            {/*    <Text isHighlight={false} size={TextSize.h3} weight={TextWeight.w500}>*/}
                            {/*        <a href={'/careers/position48'}>→ Team Lead - Self-driving cars</a><br/>*/}
                            {/*        <a href={'/careers/position49'}>→ Senior Software Engineer (C++ 14) - Self-driving cars</a><br/>*/}
                            {/*        <a href={'/careers/position50'}>→ Senior Computer Vision Engineer - Self-driving cars</a><br/>*/}
                            {/*    </Text>*/}
                            {/*</div>*/}
                        </div>
                    </div>


                    <div className={'container'} style={{color:'black', border:'1px solid transparent'}}>
                        <div className={'row'} style={{marginTop:'10px'}}>
                            <div className={'col-sm-12'} style={{marginTop:'0px'}}>
                                <PictureFrame>
                                    <img src={Images.Office11}/>
                                </PictureFrame>
                            </div>
                            <div className={'col-sm-12'} style={{marginTop:'20px'}}>
                                <Text isHighlight={false} size={TextSize.h2} weight={TextWeight.w500}>
                                    Benefits of Joining Our Team
                                </Text>
                                <VSpacer size={20}/>
                                <Text isHighlight={false} size={TextSize.h3} weight={TextWeight.w300} lineHeight={1.2}>

                                    We love our staff and it shows. Our office atmosphere is casual, with flexible working hours, great benefits, and a variety of games and events our teams attend together.

                                    Additionally, here are the benefits of working in our company:

                                    <br/>
                                    <br/>
                                    <Text isHighlight={false} size={TextSize.h3} weight={TextWeight.w500}>
                                        <a href={'#'}>→ Flexible work schedule</a><br/>
                                        <a href={'#'}>→ Great office in Georgia</a><br/>
                                        <a href={'#'}>→ Regular team building events and company outings</a><br/>
                                        <a href={'#'}>→ Challenging and fun projects</a><br/>
                                        <a href={'#'}>→ Health insurance at a private hospital chain</a><br/>
                                    </Text>
                                    <br/>
                                    <br/>


                                </Text>
                            </div>
                        </div>
                    </div>


                    <div className={'container'} style={{color:'black', border:'1px solid transparent'}}>
                        <div className={'row'} style={{marginTop:'20px'}}>
                            <div className={'col-sm-8'}>
                                <PictureFrame>
                                    <img src={Images.CompanyAddress}/>
                                </PictureFrame>
                            </div>
                            <div className={'col-sm-4'}>
                                <Text isHighlight={false} size={TextSize.h2} weight={TextWeight.w500}>
                                    Get In Touch
                                </Text>
                                <VSpacer size={25}/>

                                <Text size={TextSize.h3} weight={TextWeight.w300} padding={'0px 0px 20px 0px'} spacing={1} lineHeight={1.3}>
                                    We're always looking for passionate engineers to join our team.
                                    <br/><br/>
                                    Email <CareersLink/> with your resume and we'll get in touch to discuss next steps.
                                </Text>


                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={'col-sm-12'} style={{marginTop:'5px'}}>
                                <VSpacer size={25}/>
                                <HAligner align={HAlign.Center}>
                                    <Text size={TextSize.h2} spacing={0.9}>
                                        Let's build better together. Join our team.
                                    </Text>
                                </HAligner>
                                <VSpacer size={25}/>
                            </div>

                        </div>
                    </div>


                    <PageFooter />


                </PageBackground>;
    }
}

export default () => <CareersPage/>;






